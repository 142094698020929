import Vue from 'vue'
import Vuex from 'vuex'
import AuthService from './services/auth.service';

const user = JSON.parse(localStorage.getItem('user'));
const userState = user
    ? { status: { loggedIn: true }, user }
    : { status: { loggedIn: false}, user: null };

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    barColor: 'rgba(0, 0, 0, .8), rgba(0, 0, 0, .8)',
    barImage: require('@/assets/sidebar-1.jpg'),
    drawer: null,
    userState
  },
  mutations: {
    SET_BAR_IMAGE (state, payload) {
      state.barImage = payload
    },
    SET_DRAWER (state, payload) {
      state.drawer = payload
    },
    loginSuccess(state, user) {
        state.userState.status.loggedIn = true;
        state.userState.user = user;
    },
    loginFailure(state) {
        state.userState.status.loggedIn = false;
        state.userState.user = null;
    },
    logout(state) {
        state.userState.status.loggedIn = false;
        state.userState.user = null;
    },
    registerSuccess(state) {
        state.userState.status.loggedIn = false;
    },
    registerFailure(state) {
        state.userState.status.loggedIn = false;
    },
    refreshToken(state, accessToken) {
      state.userState.status.loggedIn = true;
      state.userState.user = { ...state.userState.user, accessToken: accessToken };
    }
  },
  actions: {
    login({ commit }, user ) {
      return AuthService.login(user).then(
          user => {
              commit('loginSuccess', user);
              return Promise.resolve(user);
          },
          error => {
              commit('loginFailure', user);
              return Promise.reject(error);
          }
      );
    },
    logout({ commit }) {
        AuthService.logout(); commit('logout');
    },
    register({ commit }, user)
    {
        return AuthService.register(user).then(
            response => {
                commit('registerSuccess');
                return Promise.resolve(response.data);
            },
            error => {
                commit('registerFailure');
                return Promise.reject(error);
            }
        );
    },
    refreshToken({ commit }, accessToken) {
      commit('refreshToken', accessToken);
    }
  },
})
