<template>
  <section class="mb-5 text-center">
    <h1
      class="font-weight-bold mb-2 headline green--text text--darken-2"
      v-text="`${heading}`"
    />

    <span
      class="font-weight-light subtitle-1"
      v-text="`${description}`"
    >
      
    </span>
  </section>
</template>

<script>
  export default {
    name: 'VComponent',

    props: {
      heading: {
        type: String,
        default: '',
      },
      description: {
        type: String,
        default: '',
      },
    },
  }
</script>
