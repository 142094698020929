//import axios from "axios";
//const API_URL = process.env.VUE_APP_API_URL  //'http://localhost:8000/api/';

import api from "./api";
import TokenService from "./token.service";

class AuthService {
    login(user) {
        return api.post('login', {
            email: user.email,
            password: user.password
        })
        .then(response => {
            if(response.data.access_token)
            {
                //localStorage.setItem('user', JSON.stringify(response.data));
                TokenService.setUser(response.data);
            }
            return response.data;
        });
    }

    logout() {
        //localStorage.removeItem('user');
        TokenService.removeUser();
    }

    register(user)
    {
        return api.post('register', {
            name: user.name,
            email: user.email,
            password: user.password
        })
    }

}

export default new AuthService();