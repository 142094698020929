<!-- eslint-disable vue/no-multiple-template-root -->
<template>
      <router-view></router-view>
</template>

<script>
  export default {
    name: 'App',
  }
</script>
<style scope>
  
</style>
